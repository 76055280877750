export const config = {
    apiBaseUrl: 'https://qa.bff.honeysoft.app',
    endpoints: {
        authenticate: '/UserAuth/authenticate',
        checkToken: '/UserAuth/checkToken',
        getDashboardData: '/Client/getDashboardData',
        getClientName: '/Client/getName',
        getUserData: '/User/getUserData',
        createIntegrationRun: '/Integration/createIntegrationRun',
        toggleIntegrationStatus: '/Integration/toggleIntegrationStatus'
    },
};
