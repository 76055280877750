import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Dashboard.css";
import { config } from "../config";
import CircleLoader from "react-spinners/CircleLoader";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import { Link } from "react-router-dom";
import { FaCopy } from "react-icons/fa";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const topOptions = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "20", label: "20" },
];

function NexusDashboard({ history }) {
  const [data, setData] = useState(null);
  const [clientName, setClientName] = useState("");
  const [products, setProducts] = useState([]);
  const [topData, setTopData] = useState("5");
  const [lastRefresh, setLastRefresh] = useState("");
  const [loading, setLoading] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const copyIUIDToClipboard = (IUID) => {
    navigator.clipboard.writeText(IUID).then(() => {
      toast.success("UID copied to clipboard!");
    });
  };

  useEffect(() => {
    // This effect runs on initial render only (due to the empty dependency array).
    checkToken(); // Call a function that checks token and redirects user if all is good.
    let intervalId; // declare this variable outside the fetchData function so that it can be accessed by both fetchData and cleanup functions.
    async function fetchData() {
      let currentDate = new Date();
      let top = topData;
      try {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        const response = await axios.get(
          `${config.apiBaseUrl}${
            config.endpoints.getDashboardData
          }?clientId=${localStorage.getItem("dataHive_clientId")}&top=${top}`
        ); // replace with actual API endpoint URL.
        setData(response.data);
        setLastRefresh(currentDate.toTimeString());
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    }
    fetchData();
    fetchProducts(); // Fetch user-specific products

    if (autoRefresh) {
      // check if auto refresh feature should be enabled or not based on boolean value stored in 'autoRefresh' state variable updated via user interaction with toggle switch component etc.. If true then proceed further else just return early from current execution context without doing anything more.
      intervalId = setInterval(fetchData, 5 * 1000); // schedule a new timer/callback function called fetchData() to run every 30 seconds (or whatever duration specified by your business requirements), and store its id returned by setInterval() method call inside 'intervalId' variable defined earlier so that we can use clearInterval(intervalId) later if needed as explained above.
    }
    return () => {
      // cleanup callback function which gets executed just before next time when this same effect runs again, or whenever component unmounts from DOM tree during its lifetime cycle inside React virtual dom hierarchy managed by React framework internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
      clearInterval(intervalId); // cancel any pending timers/callbacks scheduled previously using setInterval() method call inside cleanup callback function of our useEffect hook as explained earlier to avoid unwanted side effects such as race conditions etc., since they all share common state variables defined outside their scope inside our component definition.
    };
  }, [autoRefresh, topData]); // add 'autoRefresh' state variable as dependency in second parameter array of our useEffect hook so that desired behavior is achieved when user toggles the switch on/off

  const checkToken = async () => {
    const auth = localStorage.getItem("dataHive_authenticated");
    if (auth) {
      if (auth === "true") {
        const expiryDateStr = localStorage.getItem("dataHive_expiry"); // Get the expiration date string from local storage.
        if (expiryDateStr) {
          // If it exists, continue to parse and compare with current time.
          const token = localStorage.getItem("dataHive_userToken"); // Retrieve user token stored in local storage as well for API call later on. You should add error handling here!
          if (token) {
            const expiryDate = new Date(expiryDateStr); // Create a date object from the string retrieved above. This allows us to compare it with current time using getTime() method below.
            if (expiryDate > new Date()) {
              // Compare token's expiration time with current time in milliseconds format and check if it has not passed yet i.e., still valid!
            } else {
              localStorage.clear();
              navigate("/");
              console.warn("Token expired."); // If token has already expired based on date comparison above, log a warning message instead of showing it to users as error messages might scare them off or give away sensitive info about our backend infrastructure/tech stack etc.. :)
            }
          }
        } else {
          localStorage.clear();
          navigate("/");
          console.warn("Token not found in local storage."); // If token doesn't exist in local storage at all, log a warning message instead of showing it to users as error messages might scare them off or give away sensitive info about our backend infrastructure/tech stack etc.. :)
        }
      } else {
        localStorage.clear();
        navigate("/");
      }
    } else {
      localStorage.clear();
      navigate("/");
    }
  };

  async function fetchProducts() {
    try {
      const userId = localStorage.getItem("dataHive_userId");
      const response = await axios.get(
        `${config.apiBaseUrl}${config.endpoints.getUserData}?userId=${userId}`
      );
      setProducts(response.data.userProducts.$values);
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  }
  function handleLogout() {
    // custom event handler function called whenever logout button is clicked by end users. This removes token from local storage or cookies/sessionStorage, and redirects back to login page using history object provided by React Router library internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
    localStorage.clear(); // remove token from local storage or cookies/sessionStorage depending upon implementation details related to security and performance optimization of your specific use case scenario inside real-world business environment where this application may be used daily by thousands or millions of end users simultaneously without any downtime issues etc..
    navigate("/"); // redirect back to login page using 'history' object provided by React Router library internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
  }

  async function handleIntegrationRun(iuid) {
    const wait = axios.post(
      `${config.apiBaseUrl}${config.endpoints.createIntegrationRun}?iuid=${iuid}`
    ).then((response) => 
      new Promise((resolve) => setTimeout(() => resolve(response), 2000))
    );
  
    toast.promise(
      wait,
      {
        loading: "Adding ad-hoc run to schedule...",
        success: <b>Added ad-hoc run!</b>,
        error: <b>Could not add ad-hoc run.</b>,
      },
      {
        style: {
          minWidth: "350px",
        },
      }
    );
    setAutoRefresh(!autoRefresh);
    setAutoRefresh(!autoRefresh);
  }  

  async function handleIntegrationDisable(iuid, currentStatus) {
    const wait = axios.post(
      `${config.apiBaseUrl}${config.endpoints.toggleIntegrationStatus}?iuid=${iuid}`
    ).then((response) => 
      new Promise((resolve) => setTimeout(() => resolve(response), 2000))
    );
  
    const text = currentStatus === "Disabled" ? "Enabling" : "Disabling";
    toast.promise(
      wait,
      {
        loading: text + " integration...",
        success: <b>{text} integration success!</b>,
        error: <b>{text} integration failed.</b>,
      },
      {
        style: {
          minWidth: "350px",
        },
      }
    );

    setAutoRefresh(!autoRefresh);
    setAutoRefresh(!autoRefresh);
  }

  async function fetchClientName() {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}${
          config.endpoints.getClientName
        }?clientId=${localStorage.getItem("dataHive_clientId")}`
      ); // replace with actual API endpoint URL.
      setClientName(
        localStorage.getItem("dataHive_username") + " (" + response.data + ")"
      );
    } catch (error) {
      console.log("Error fetching client name:", error);
    }
  }
  fetchClientName();

  return (
    <div>
      <header className="header">
        {/* render toggle switch component for auto refresh feature */}
        <div className="header-left">
          <img
            src="https://sanexuspreprod.blob.core.windows.net/datahive-images/nexus.png"
            alt="Logo"
            className="header-logo"
            onClick={toggleSidebar}
          />
        </div>
        <div className="header-center">
          <h3 className="section-header">Nexus Dashboard</h3>
        </div>
        <div className="header-right">
          {/* render client name label if available */}
          <div className="logged-in-as">
            <label className="logged-in-as">Logged in as:</label>
            {clientName && (
              <label className="logged-in-as" htmlFor="client-name">
                {clientName}
              </label>
            )}
          </div>
          {/* add logout button */}
          <button onClick={handleLogout} className="submit-button">
            Logout
          </button>
        </div>
      </header>
      <div className="dashboard">
        <div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
        <div className="main-content">
          <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
            <button className="close-btn" onClick={toggleSidebar}>
              ×
            </button>
            <nav>
              <ul>
                <li className="inactive-link">
                  <Link to="/dashboard" onClick={toggleSidebar}>
                    <img
                      src="https://sanexuspreprod.blob.core.windows.net/datahive-images/datahive.png"
                      className="sidebar-logo"
                    />
                    <span className="sidebar-text">Home</span>
                  </Link>
                </li>
                {products.map((product, index) => (
                  <li
                    key={index}
                    className={
                      location.pathname === product.route
                        ? "active-link"
                        : "inactive-link"
                    }
                    style={{
                      display:
                        !product.enabled || !product.access ? "none" : "auto ",
                    }}
                  >
                    <Link
                      to={
                        product.route.includes("/swagger")
                          ? `/publicAPI?route=${encodeURIComponent(
                              product.route
                            )}`
                          : product.route
                      }
                      onClick={toggleSidebar}
                      className={`sidebar-link ${
                        location.pathname === product.route ? "disabled" : ""
                      }`}
                      style={{
                        pointerEvents:
                          location.pathname === product.route ||
                          !product.enabled ||
                          !product.access
                            ? "none"
                            : "auto",
                      }}
                    >
                      <img src={product.image} className="sidebar-logo" />
                      <span className="sidebar-text">{product.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <button className="toggle-stats" onClick={toggleSidebar}>
            {sidebarOpen ? "←" : "→"}
          </button>
          <div className="section-header">
            <h1>Integrations:</h1>
          </div>
          {/* render table only if data is available */}
          {data && data.integrationList && data.integrationList.$values ? (
            <table className="table">
              <thead>
                <tr>
                  <th>UID</th>
                  <th>Description</th>
                  <th>Data Transfer Object</th>
                  <th>Source</th>
                  <th>Target</th>
                  <th>Trigger</th>
                  <th>Trigger Details</th>
                  <th>Status</th>
                  <th>Manual Run</th>
                  <th>Enable/Disable</th>
                </tr>
              </thead>
              <tbody>
                {data.integrationList.$values.map((item, index) => (
                  <tr key={index}>
                    <td>
                    {item.integrationUID.slice(0,10)}...
                      <button
                        onClick={() => copyIUIDToClipboard(item.integrationUID)}
                        className="icon-button-centre"
                      >
                        <FaCopy />
                      </button>
                    </td>
                    <td>{item.description}</td>
                    <td>{item.schemaType}</td>
                    <td>{item.sourceSystem}</td>
                    <td>{item.targetSystem}</td>
                    <td>{item.triggerType}</td>
                    <td>{item.triggerDetail}</td>
                    <td>{item.status}</td>
                    <td>
                      <button
                        onClick={() =>
                          handleIntegrationRun(item.integrationUID)
                        }
                        className="submit-button"
                      >
                        Run
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          handleIntegrationDisable(
                            item.integrationUID,
                            item.status
                          )
                        }
                        className="submit-button"
                      >
                        {item.status === "Disabled" ? "Enable" : "Disable"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
          <div className="section-header">
            <div className="prev-header">
              <h1>Integration Runs:</h1>
            </div>
            <div className="top-select-container">
              <div className="select-text">
                <label htmlFor="top">Record count: </label>
              </div>
              <Select
                className="top-select"
                instanceId="top"
                defaultValue="5"
                onChange={(e) => setTopData(e.value)}
                options={topOptions}
                placeholder="5"
              />
            </div>
          </div>
          {/* render table only if data is available */}
          {data && data.masterList && data.masterList.$values ? (
            <table className="table last-table">
              <thead>
                <tr>
                  <th>Data Transfer Object</th>
                  <th>Source</th>
                  <th>Target</th>
                  <th>Status</th>
                  <th>Start Date & Time</th>
                  <th>Received Date & Time</th>
                  <th>Received Count</th>
                  <th>Sent Date & Time</th>
                  <th>Sent Count</th>
                  <th>Completed Date & Time</th>
                </tr>
              </thead>
              <tbody>
                {data.masterList.$values.map((item, index) => (
                  <tr key={index}>
                    <td>{item.dataTransferObject}</td>
                    <td>{item.sourceSystem}</td>
                    <td>{item.targetSystem}</td>
                    <td>{item.status}</td>
                    <td>{item.startDateTime}</td>
                    <td>{item.receivedDateTime}</td>
                    <td>{item.receivedCount}</td>
                    <td>{item.sentDateTime}</td>
                    <td>{item.sentCount}</td>
                    <td>{item.endDateTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}

          {loading && (
            <div
              className="loading-spinner-container"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
              }}
            >
              <CircleLoader
                color="#f06d37"
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
        <div className="refresh">
          <div className="auto-refresh">
            <label htmlFor="auto-refresh">Enable Auto Refresh:</label>
            <input
              type="checkbox"
              id="auto-refresh"
              checked={autoRefresh}
              onChange={() => setAutoRefresh(!autoRefresh)}
            />
          </div>
          {lastRefresh && (
            <label className="auto-refresh" htmlFor="last-refresh">
              Last Refresh: {lastRefresh}
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
export default NexusDashboard;
