import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import NexusDashboard from './nexus/Dashboard';
import DataHiveDashboard from './DataHiveDashboard';
import DataHiveSettings from './settings/Settings';
import DataHiveAPI from './api/API';

   function App() {
     return (
       <Router>
         <Routes>
           <Route path="/" element={<LoginPage />} />
           <Route path="/nexus/dashboard" element={<NexusDashboard />} />
           <Route path="/dashboard" element={<DataHiveDashboard />} />
           <Route path="/settings" element={<DataHiveSettings />} />
           <Route path="/publicAPI" element={<DataHiveAPI />} />
         </Routes>
       </Router>
     );
   }

   export default App;
