import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DataHiveDashboard.css";
import { config } from "./config";
import CircleLoader from "react-spinners/CircleLoader";
import { Toaster } from "react-hot-toast";
import { Link } from 'react-router-dom';


const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function DataHiveDashboard({ history }) {
  const [data, setData] = useState(null);
  const [clientName, setClientName] = useState("");
  const [products, setProducts] = useState([]);
  const [topData, setTopData] = useState("5");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // This effect runs on initial render only (due to the empty dependency array).
    checkToken(); // Call a function that checks token and redirects user if all is good.
    let intervalId; // declare this variable outside the fetchData function so that it can be accessed by both fetchData and cleanup functions.
    async function fetchData() {
      try {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        const response = await axios.get(
          `${config.apiBaseUrl}${
            config.endpoints.getDashboardData
          }?clientId=${localStorage.getItem("dataHive_clientId")}&top=1`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    }
    fetchData();
    fetchProducts(); // Fetch user-specific products

    return () => {
      // cleanup callback function which gets executed just before next time when this same effect runs again, or whenever component unmounts from DOM tree during its lifetime cycle inside React virtual dom hierarchy managed by React framework internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
      clearInterval(intervalId); // cancel any pending timers/callbacks scheduled previously using setInterval() method call inside cleanup callback function of our useEffect hook as explained earlier to avoid unwanted side effects such as race conditions etc., since they all share common state variables defined outside their scope inside our component definition.
    };
  }, [topData]); // add 'autoRefresh' state variable as dependency in second parameter array of our useEffect hook so that desired behavior is achieved when user toggles the switch on/off

  const checkToken = async () => {
    const auth = localStorage.getItem("dataHive_authenticated");
    if (auth) {
      if (auth === "true") {
        const expiryDateStr = localStorage.getItem("dataHive_expiry"); // Get the expiration date string from local storage.
        if (expiryDateStr) {
          // If it exists, continue to parse and compare with current time.
          const token = localStorage.getItem("dataHive_userToken"); // Retrieve user token stored in local storage as well for API call later on. You should add error handling here!
          if (token) {
            const expiryDate = new Date(expiryDateStr); // Create a date object from the string retrieved above. This allows us to compare it with current time using getTime() method below.
            if (expiryDate > new Date()) {
              // Compare token's expiration time with current time in milliseconds format and check if it has not passed yet i.e., still valid!
            } else {
              localStorage.clear();
              navigate("/");
              console.warn("Token expired."); // If token has already expired based on date comparison above, log a warning message instead of showing it to users as error messages might scare them off or give away sensitive info about our backend infrastructure/tech stack etc.. :)
            }
          }
        } else {
          localStorage.clear();
          navigate("/");
          console.warn("Token not found in local storage."); // If token doesn't exist in local storage at all, log a warning message instead of showing it to users as error messages might scare them off or give away sensitive info about our backend infrastructure/tech stack etc.. :)
        }
      } else {
        localStorage.clear();
        navigate("/");
      }
    } else {
      localStorage.clear();
      navigate("/");
    }
  };

  async function fetchProducts() {
    try {
      const userId = localStorage.getItem("dataHive_userId");
      const response = await axios.get(
        `${config.apiBaseUrl}${config.endpoints.getUserData}?userId=${userId}`
      );
      setProducts(response.data.userProducts.$values);
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  }
  function handleLogout() {
    // custom event handler function called whenever logout button is clicked by end users. This removes token from local storage or cookies/sessionStorage, and redirects back to login page using history object provided by React Router library internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
    localStorage.clear(); // remove token from local storage or cookies/sessionStorage depending upon implementation details related to security and performance optimization of your specific use case scenario inside real-world business environment where this application may be used daily by thousands or millions of end users simultaneously without any downtime issues etc..
    navigate("/"); // redirect back to login page using 'history' object provided by React Router library internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
  }

  async function fetchClientName() {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}${
          config.endpoints.getClientName
        }?clientId=${localStorage.getItem("dataHive_clientId")}`
      ); // replace with actual API endpoint URL.
      setClientName(
        localStorage.getItem("dataHive_username") + " (" + response.data + ")"
      );
    } catch (error) {
      console.log("Error fetching client name:", error);
    }
  }
  fetchClientName();

  return (
    <div>
      <header className="header">
        {/* render toggle switch component for auto refresh feature */}
        <div className="header-left">
          <img src="https://sanexuspreprod.blob.core.windows.net/datahive-images/datahive.png" alt="Logo" className="header-logo" />
        </div>
        <div className="header-center">
          <h3 className="section-header">DataHive Dashboard</h3>
        </div>
        <div className="header-right">
          {/* render client name label if available */}
          <div className="logged-in-as">
            <label className="logged-in-as">Logged in as:</label>
            {clientName && (
              <label className="logged-in-as" htmlFor="client-name">
                {clientName}
              </label>
            )}
          </div>
          {/* add logout button */}
          <button onClick={handleLogout} className="submit-button">
            Logout
          </button>
        </div>
      </header>
      <div className="dashboard">
        <div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
        <div className="main-content">
          <div className="product-grid">
            {products.map((product, index) => {
              const isDisabled = !product.enabled || !product.access;
              const overlayText = !product.enabled ? "Coming Soon!" : "Disabled for user";

              return (
                <div key={index} className="product-button-wrapper">
                  <Link
                    to={product.route}
                    className={`product-button ${isDisabled ? "disabled" : ""}`}
                    style={{ pointerEvents: isDisabled ? "none" : "auto" }}
                  >
                    <img src={product.image} alt={product.name} />
                    <span>{product.name}</span>
                  </Link>
                  {isDisabled && <div className="overlay">{overlayText}</div>}
                </div>
              );
            })}
          </div>

          {loading && (
            <div
              className="loading-spinner-container"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
              }}
            >
              <CircleLoader
                color="#f06d37"
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
        <div className="refresh">
          <div className="auto-refresh">
            <label htmlFor="auto-refresh">Copyright 2024 Honeysoft</label>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DataHiveDashboard;
